<template>
  <div>
    <b-modal centered hide-footer v-model="modal">
      <div>
        <label>Название:</label>
        <b-form-input
            v-model="category.name">
        </b-form-input>
        <label>Изображение:</label>
        <br>
        <div style="height: 200px;background-color: #E5E5E5"
             class="d-flex justify-content-center align-items-center mb-2 p-3">
          <img style="height: 150px;" :src="menu_catimg_url"/>
        </div>
        <input type="file" @change="setCatImg" ref="category_image" accept=".png" id="formFile">
        <br>
        <div style="display: flex; flex-direction: row; justify-content: space-between">
          <br>
          <b-button variant="success" style=" align-self: flex-end" @click="sendCat">Сохранить</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal centered hide-footer v-model="delModal">
      <template #modal-header>
        <strong> Удалить категорию? </strong>
      </template>
      <br>
      <b-button variant="success" style="margin-top: 10px" @click="rmCat">Подтвердить</b-button>
    </b-modal>


    <b-container class="mt-5">
      <b-card no-body>
        <div class="d-flex" style="margin-left: 15px">
          <b-button @click="openModal" variant="success" style="margin-top: 15px">Добавить</b-button>
        </div>
      <b-table style="margin-top: 50px" responsive hover :busy="isBusy" :fields="table.fields" :items="categories">

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:cell(image)="{item}">
          <img :src=item.image style="height: 100px;">
        </template>

        <template v-slot:cell(remove)="{item}">
          <b-button variant="danger" style="margin-top: 30px" @click="rmModal(item._id)">Удалить</b-button>
        </template>

      </b-table>
      </b-card>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      table: {
        fields: [{label: "Название категории", key: "name"},
          {label: "Изображение", key: "image"},
          {label: "", key: "remove"}]
      },
      categories: [],
      category: {
        name: '',
        image: '',
      },
      modal: false,
      delModal: false,
      image_file: null,
      menu_catimg_url: '',
      removeId: '',
      isBusy:true
    }
  }, async mounted() {
    const {data} = await this.$http.get('https://api.data-krat.ru/v2/admin/categories/images/all');
    this.categories = data;
    this.isBusy=false;
  },
  methods: {
    openModal(row) {
      console.log(row)
      this.category = row;
      this.modal = true;
    }, setCatImg() {
      this.image_file = this.$refs.category_image.files[0]
      this.menu_catimg_url = URL.createObjectURL(this.image_file)
    },
    async sendCat() {

      let formData = new FormData();
      formData.append('image', this.image_file);
      formData.append('name', this.category.name);
      try {
        const {data} = await this.$http.post('https://images.data-krat.ru/images/categories', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        this.$notify({
          group: 'foo',
          title: 'Успешно',
          type: 'success',
          text: 'Изображение успешно загружено'
        })
        this.modal = false;
      } catch (e) {
        console.log('err', e)
        this.$notify({
          group: 'foo',
          title: 'Important message',
          type: 'error',
          text: 'Ошибка'
        })
      }
    },
    async rmModal(id) {
      this.delModal = true;
      this.removeId = id;
      console.log(this.removeId);
    },
    async rmCat() {
      try {
        await this.$http.delete('https://api.data-krat.ru/v2/admin/categories/images/' + this.removeId, {
          headers: {
            'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjVmMjE2MDMzOTNmMDgzNjhhMmZkNmIwZSIsImFwcF9uYW1lIjoiZmFzb2wifQ.8hRWClmLUhfu6ufWflOf3suexktScq3QBuMwpYPwlEc'
          }
        })
        this.$notify({
          group: 'foo',
          title: 'Успешно',
          type: 'success',
          text: 'Категория удалена'
        })
      } catch (e) {
        console.log('err', e)
        this.$notify({
          group: 'foo',
          title: 'Ошибка удаления',
          type: 'error',
          text: 'Не удалено'
        })
      }
    }
  },
}
</script>

<style scoped>

</style>