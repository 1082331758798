<template>
  <div class="mt-3">
    <h4 style="text-align: center">Аналитика</h4>
    <b-container class="cont-main">
      <date-range-picker
          class="mt-3"
          ref="picker"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
          :timePicker24Hour="true"
          v-model="dateRange"
          @update="updateValues"
          showDropdowns

      >
        <template v-slot:input="picker">
          <div   style="max-width: 250px;width: 250px;">
            {{ formatDate(picker.startDate)  }} - {{ formatDate(picker.endDate)  }}
          </div>

        </template>
      </date-range-picker>
      <b-table :fields="table.fields" :busy="isBusy" :items="anal" hover sort-by="count" sort-desc fixed>
        <template slot="thead-top" slot-scope="data">
          <tr>
            <th v-for="field in table.fields" :key="field.key" class="text-right">
              {{ field.label === 'APP' ? '' : field.label }}
            </th>
          </tr>
          <tr>
            <td><b>Итого:</b></td>
            <td class="text-right"> {{ new Intl.NumberFormat('ru-RU').format(avg_anal.sum) }}</td>
            <td class="text-right"> {{ new Intl.NumberFormat('ru-RU').format(avg_anal.card_sum) }}</td>
            <td class="text-right"> {{ new Intl.NumberFormat('ru-RU').format(avg_anal.preorder_sum) }}</td>
            <td class="text-right"> {{ new Intl.NumberFormat('ru-RU').format(avg_anal.count) }}</td>
            <td class="text-right"></td>
            <td class="text-right"></td>
            <td class="text-right">{{ new Intl.NumberFormat('ru-RU').format(avg_anal.mau.toFixed(0)) }}</td>
            <td class="text-right">{{ new Intl.NumberFormat('ru-RU').format(avg_anal.dau.toFixed(0)) }}</td>
          </tr>
          <tr>
            <td><b>Итого, среднее:</b></td>
            <td class="text-right"> {{
                new Intl.NumberFormat('ru-RU').format((avg_anal.sum / anal.length).toFixed(2))
              }}
            </td>
            <td class="text-right"> {{
                new Intl.NumberFormat('ru-RU').format((avg_anal.card_sum / anal.filter(el => el.card_sum > 0).length).toFixed(2))
              }}
            </td>
            <td class="text-right">
              {{ new Intl.NumberFormat('ru-RU').format((avg_anal.preorder_sum / anal.length).toFixed(2)) }}
            </td>
            <td class="text-right"> {{
                new Intl.NumberFormat('ru-RU').format((avg_anal.count / anal.length).toFixed(0))
              }}
            </td>
            <td class="text-right">
              {{ new Intl.NumberFormat('ru-RU').format((avg_anal.avg / anal.length).toFixed(2)) }}
            </td>
            <td class="text-right">
              {{ new Intl.NumberFormat('ru-RU').format((avg_anal.avg_ltv / anal.length).toFixed(2)) }}
            </td>
            <td class="text-right">
              {{ new Intl.NumberFormat('ru-RU').format((avg_anal.mau / anal.length).toFixed(0)) }}
            </td>
            <td class="text-right">
              {{ new Intl.NumberFormat('ru-RU').format((avg_anal.dau / anal.length).toFixed(0)) }}
            </td>
          </tr>
        </template>


        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:cell(sum)="{item}">
          {{ new Intl.NumberFormat('ru-RU').format(item.sum) }}
        </template>

        <template v-slot:cell(card_sum)="{item}">
          {{ new Intl.NumberFormat('ru-RU').format(item.card_sum) }}
        </template>

        <template v-slot:cell(preorder_sum)="{item}">
          {{ new Intl.NumberFormat('ru-RU').format(item.preorder_sum) }}
        </template>

        <template v-slot:cell(avg_ltv)="{item}">
          {{ new Intl.NumberFormat('ru-RU').format(item.avg_ltv) }}
        </template>

        <template v-slot:cell(avg)="{item}">
          {{ new Intl.NumberFormat('ru-RU').format(item.avg) }}
        </template>

        <template v-slot:cell(mau)="{item}">
          {{ Number(new Intl.NumberFormat('ru-RU').format(Number(item.mau).toFixed(0))) }}
        </template>

        <template v-slot:cell(dau)="{item}">
          {{ Number(new Intl.NumberFormat('ru-RU').format(Number(item.dau).toFixed(0))) }}
        </template>

      </b-table>
    </b-container>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
export default {

  name: "Analytics",
  components: { DateRangePicker },
  data() {
    return {
      dateRange:{
        endDate:new Date(),
        startDate:new Date((new Date()).setMonth((new Date()).getMonth() - 1))
      },
      table: {
        fields: [
          {key: 'app', label: 'APP'},
          {key: 'sum', label: 'R', class: 'text-right', sortable: true},
          {key: 'card_sum', label: 'R Online', class: 'text-right', sortable: true},
          {key: 'preorder_sum', label: 'R предзаказ', class: 'text-right', sortable: true},
          // {key: 'ltv', label: 'LTV',class:'text-right'},
          {key: 'count', label: 'Кол-во чеков', sortable: true, class: 'text-right'},
          {key: 'avg', label: 'Ср. чек', class: 'text-right', sortable: true},
          {key: 'avg_ltv', label: 'LTV среднее', class: 'text-right', sortable: true},
          {key: 'mau', label: 'MAU', class: 'text-right', sortable: true},
          {key: 'dau', label: 'DAU', class: 'text-right', sortable: true},

        ]
      },
      anal: [],
      avg_anal: {
        sum: '',
        card_sum: 0,
        preorder_sum: 0,
        avg_ltv: 0,
        count: 0,
        avg: 0,
        dau: 0,
        mau: 0
      },
      isBusy: true
    }
  },
  async mounted() {
    await this.getAnal()
  },
  methods: {
    updateValues(date){
      console.log(this.dateRange)
      this.getAnal();
    },
    formatDate(dt){
     const MyDate = new Date(dt)
      return ('0' + MyDate.getDate()).slice(-2) + '/' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '/' + MyDate.getFullYear();
    },
    async getAnal() {
      this.isBusy = true;
      const date = new Date();
      date.setMonth(date.getMonth() - 1);

      let {data} = await this.$http.get('https://api.data-krat.ru/v2/analytics/app/user/analytics', {
        params: {
          begin_date: this.dateRange.startDate,
          end_date: this.dateRange.endDate
        }
      })
      this.anal = data;
      this.avg_anal.sum = data.reduce((total, next) => total + +next.sum, 0);
      this.avg_anal.card_sum = data.reduce((total, next) => total + +next.card_sum, 0);
      this.avg_anal.preorder_sum = data.reduce((total, next) => total + +next.preorder_sum, 0);
      this.avg_anal.avg_ltv = data.reduce((total, next) => total + +next.card_sum, 0);
      this.avg_anal.avg = data.reduce((total, next) => total + +next.avg, 0);
      this.avg_anal.count = data.reduce((total, next) => total + +next.count, 0);
      this.avg_anal.dau = data.reduce((total, next) => total + +next.dau, 0);
      this.avg_anal.mau = data.reduce((total, next) => total + +next.mau, 0);
      this.isBusy = false;
      console.log(data)
    }
  },
}
</script>

<style scoped>

.cont-main {
  margin-top: 30px;
  border-radius: 4px;
  border: 1px solid #e0dfdf;
  -webkit-box-shadow: 4px 4px 13px 6px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 13px 6px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 13px 6px rgba(34, 60, 80, 0.2);
}

</style>